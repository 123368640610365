import React from "react"

import Layout from "../components/layout/es"
import SEO from "../components/seo"

const NotFoundPage = (props) => (
  <Layout location={props.location}>
    <SEO title="404: No encontrada" />
    <h1>PÁGINA NO ENCONTRADA</h1>
    <p>Has intentado navegar en una ruta que no existe.</p>
  </Layout>
)

export default NotFoundPage
